@import '../bootstrap/variable';

.modal-content{
    .modal-footer{
        margin: 0px auto;
    }
  
    .btn-primary{
        background-color: $primary-color;
    }
    .btn-secondary{
        background-color: white;
        color:$primary-color;
        border-color:$primary-color;
        // width: 100px;
    }
}

.modal-backdrop.show {
    opacity: 0.9;
    background: $text-color;
}

.alignCenter {
    text-align: center;
}

.alignRight {
    text-align: right;
}

.alignCenterWK {
    text-align: -webkit-center;
}

.pointer {
    cursor: pointer;
}