@import '../bootstrap/variable';

.text-editor {
    padding: 10px;
    width:100%;
    height: 160px;
}

.none-bottom-border {
    border-bottom: none !important;
}

.rich-editor-wrapper {
    border: 1px solid #ccc;
}

.editor-footer {
    padding: 10px;
    background-color: $white;
    text-align: end;
    border-top: none;
}

.text-editor-error {
    color: red;
    font-size: 600;
}