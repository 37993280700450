@import "./bootstrap/variable";

.header {
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
}

.alignContentCenter {
  align-self: center;
}

.sub-header {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
}

.sub-header-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: $sub-header-text;
}

.border-line {
  border: 1px solid $border;
  width: 75%;
}

.full-wrap {
  overflow-wrap: anywhere;
}

.floatRight {
  float: right;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.paddingLeft-10 {
  padding-left: 10px;
}

.marginRight-10 {
  margin-right: 10px;
}

.tag-btn {
  background-color: $white;
  border: 1px solid $border;
}

.displayNone {
  display: none;
}

.opacity0 {
  // color: white;
  opacity: 0;
}

.noWrap {
  white-space: nowrap;
}

.disable-form {
  background: white !important;
  border: 0px !important;
}

#tooltip-right {
  opacity: 1;
}
#tooltip-right-common {
  opacity: 1;
}

#tooltip-right > .tooltip-inner {
  background-color: $primary-color;
  color: $white;
  border: 1px solid #062e56;
}

#tooltip-right > .tooltip-arrow {
  border-top: 5px solid $primary-color;
}

#tooltip-category-right > .tooltip-inner {
  background-color: $primary-color;
  color: $white;
  border: 1px solid #062e56;
  max-width: 310px;
}
#tooltip-category-right {
  opacity: 1;
}
#tooltip-right-common > .tooltip-inner {
  background-color: $primary-color;
  color: $white;
  border: 1px solid #062e56;
}
@media only screen and (min-width: 900px) {
  #tooltip-right > .tooltip-inner {
    max-width: 75% !important;
    margin-left: 120px;
  }
}

#tooltip-right > .tooltip.show {
  max-width: 75% !important;
}

#tooltip-right > .tooltip-arrow {
  border-top: 5px solid $primary-color;
}
.tooltip-image {
  width: 100% !important ;
  height: 100% !important ;
}
.select__control-Z-index {
  z-index: 0 !important;
}

.z-index-1000 {
  z-index: 10000 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.webkit-auto {
  -webkit-appearance: auto !important;
  -webkit-appearance: revert !important;
}

// ckEditor css

.cke_bottom {
  display: none !important;
}

.baseLayout-container {
  // overflow-y: auto !important;
}

/* intl tel input override*/

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  padding-left: 5px;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  display: flex;
}

.intl-tel-input {
  width: 100%;
}
