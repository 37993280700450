@import "../bootstrap/variable";

.flex-column {
  background-color: $primary-color;
  width: $sidebar-width;
  height: 100%;
  left: 0px;
  display: flex;
  position: fixed;
  transition: all 1s;
}
.sidebar__list-item,
.sidebar_toggle_icon {
  //position: relative;
  display: flex;
  padding: 18px 0px 18px 27px;
}

.sidebar_toggle_icon {
  top: 65px;
}

.sidebar-label {
  margin-left: 15px;
  opacity: 0.3 !important;
  color: $white;
  transition: all 1s;
}

.active {
  background: $sidebar-item-hover;
  .svg-opacity,
  .sidebar-label {
    opacity: 1 !important;
  }
}

.sidebar__list-item:hover {
  background-color: $sidebar-item-hover;
}
.sidebar__list-item--logo {
  position: relative;
  top: 10px;
  padding: 18px 0 18px 14px;
  margin: auto;
}
.sidebar__list-item--expand {
  margin: 0px 17px auto;
  position: absolute;
}
.flex-column.active-sidebar {
  width: 250px;
  transition: all 1s;
}

.sidebar-content {
  white-space: nowrap;
}

.sidebar-content-last {
  .privacy-policy {
    margin-left: 4px;
    margin-top: 1rem;
    svg {
      height: 24px;
      path {
        fill: white;
        opacity: 0.3;
      }
    }
  }
}

.policy-modal .pointer {
  position: absolute;
  top: 1%;
  right: 4%;
}

@media only screen and (min-height: 550px) {
  .sidebar-content-last {
    position: absolute;
    bottom: 5%;
    margin-left: 9px;
  }
}

@media only screen and (max-height: 550px) {
  .sidebar-content-last {
    position: relative;
    top: 20%;
    white-space: nowrap;
    margin-left: 9px;
  }
}

.sidebar__list-item.active-sidebar {
  text-align: left;
  transition: all 1s;
}
.sidebar__list-item--logo.active-sidebar {
  margin: 0 auto;
  text-align: left;
  transition: all 1s;
}

.arrow-right {
  padding: 0.5rem 0px !important;
}
