@import '../bootstrap/variable';

$image-width-height: 135px;

.imgMultiSelect{
    border: 2px rgba(0, 0, 0, 0.63) dashed;
    border-radius: 17px;
    width: 10rem;
    height: 10rem;
    stroke-dashoffset: 3;
    background :url(./../../components/Common/icons/Add.png) no-repeat center;
    background-size: 100px;
    
        
}
.imgUserProfile{
  border: 2px rgba(0, 0, 0, 0.63) dashed;
  border-radius: 17px;
  width: 10rem;
  height: 10rem;
  stroke-dashoffset: 3;
  background :url(./../../components/Common/icons/SingleUser.svg) no-repeat center;
  background-size: 100px;
  
      
}
.imgMultiSelect:hover {
    border: 2px grey dashed;
    background: "";
    position: relative;
    opacity: 0.8;
    background :url(./../../components/Common/icons/Add.png) no-repeat center;
    background-size: 100px;
    
}

.imgMultiselect-img {
    width: $image-width-height;
    height: $image-width-height;
}

.imgMultiselect-img-close-img{
    display: none;
    position: relative;
  }

.imgMultiselect-img:hover .close-icon-img {
    transition: all 0.3s ease;
    text-align: center;
      
}

.img-container {
  position:relative;
  width: $image-width-height;
  height: $image-width-height;
  margin: 0 0.5rem;
}

.drag-image {
  display: block;
  width: $image-width-height;
  height: $image-width-height;
}
.drag-image-modal{
  display: block;
  width: -webkit-fill-available;
  height: 400px;
}

.scroll-right {
  display: flex;
  // overflow-y: auto;
  overflow-x: auto;
}

.width-mid {
  width:min-content;
}

.image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #c8dae0;
}


// .img-container:hover .image-overlay {
//   opacity: 1;
//   background :url(./../../components/Common/icons/imagePreview.png) no-repeat center !important;
//   height: 50px;
//   fill: black;
// }

// .img-container:hover .drag-image {
//   opacity: 0.4;
//   filter: blur(10px)
// }

.imgUserProfile:hover{
  opacity: 1;
  background :url(./../../components/Common/icons/plusIcon.png) no-repeat center;
  fill: black;
  transition: 0.3s;
}
.text {
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.ril__deleteButton{
  background: url(./../../components/Common/icons/delete.svg) no-repeat center;
  text-transform: none;
}
.ril__deleteButton:hover{
  background: url(./../../components/Common/icons/delete.svg) no-repeat center;
  text-transform: none;
  outline: none;
}
.ril__deleteButton:focus{
  background: url(./../../components/Common/icons/delete.svg) no-repeat center;
  text-transform: none;
  outline: none;
  border: none;
}