@media only screen and (max-width: 768px) {
  .mainpage {
    left: 0px !important;
    transition: all 1s;

    h3 {
      font-size: 30px !important;
      font-weight: 400;
    }

    h5 {
      font-size: 20px !important;
    }
  }

  .mainpage__transform {
    left: 30%;
    width: 70%;
  }

  .flex-column.active-sidebar {
    width: 30% !important;
  }

  .dashboard-header {
    top: 75% !important;
    left: 55% !important;
  }

  // .background-setup {
  //     height: 140vh;
  // }

  .border-line {
    width: 100%;
  }

  // showing sidebar icon for mobile design's
  .mobile-sidebar {
    position: absolute;
    z-index: 100;
    transition: all 1s;
    padding: 10px;
  }

  .set-absolute {
    display: block !important;
  }

  .breadcrumbs {
    margin-top: 60px;
  }

  .hotspot-button-success {
    width: 25px !important;
    height: 25px !important;
  }

  .drag-image-modal {
    display: block;
    width: -webkit-fill-available;
    height: 200px !important;
  }

  .content {
    margin-bottom: 20px !important;
  }
  #tooltip-right > .tooltip-inner {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 500px) {
  .mainpage {
    h3 {
      font-size: 20px;
    }

    h5 {
      font-size: 20px;
    }
  }

  .btn-primary {
    font-size: 11px;
  }

  .btn-secondary {
    font-size: 11px;
  }

  td {
    font-size: 10px;
  }

  th {
    font-size: 10px;
  }

  .mainpage__transform {
    left: 100%;
  }

  .flex-column.active-sidebar {
    width: 100% !important;
  }

  .hotspot-button-success {
    width: 15px !important;
    height: 15px !important;
  }

  .drag-image-modal {
    display: block;
    width: -webkit-fill-available;
    height: 200px !important;
  }

  .content {
    margin-bottom: 35px !important;
  }
  #tooltip-right > .tooltip-inner {
    max-width: 100% !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) and (min-width:1800px) {
  .dashboard-card {
    height: 150px !important;
    width: 150px !important;
  }

  .dashboard-header {
    top: 50% !important;
    left: 50% !important;
  }

  .welcome_text {
    font-weight: 400 !important;
  }
  
  .mainpage__transform {
    // left: 35% !important;
    width: 85% !important;
    
    .dashboard-card {
      height: 120px !important;
      width: 120px !important;
    }
  }

  .mainpage__transform {
    left: 15% !important;


  }

  .flex-column.active-sidebar {
    width: 15% !important;
  }

  .drag-image-modal {
    display: block;
    width: -webkit-fill-available;
    height: 200px !important;
  }
  #tooltip-right > .tooltip-inner {
    max-width: 100% !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  .dashboard-card {
    height: 150px !important;
    width: 150px !important;
  }

  .dashboard-header {
    top: 50% !important;
    left: 50% !important;
  }

  .welcome_text {
    font-weight: 400 !important;
  }
  
  .mainpage__transform {
    // left: 35% !important;
    width: 75% !important;
    
    .dashboard-card {
      height: 120px !important;
      width: 120px !important;
    }
  }

  .mainpage__transform {
    left: 25% !important;


  }

  .flex-column.active-sidebar {
    width: 25% !important;
  }

  .drag-image-modal {
    display: block;
    width: -webkit-fill-available;
    height: 200px !important;
  }
  #tooltip-right > .tooltip-inner {
    max-width: 100% !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 2) {
  .dashboard-card {
    height: 150px !important;
    width: 150px !important;
  }

  .dashboard-header {
    top: 50% !important;
    left: 50% !important;
  }

  .welcome_text {

    font-weight: 400 !important;
  }

  .mainpage__transform {
    width: 75% !important;
    .welcome_text {
      // line-height: 20px !important;
      font-weight: 400 !important;
    }

    .dashboard-header {
      top: 50% !important;
      left: 50% !important;
    }
  }

  .mainpage__transform {
    left: 25% !important;
    
  }

  .flex-column.active-sidebar {
    width: 25% !important;
    
  }

  .drag-image-modal {
    display: block;
    width: -webkit-fill-available;
    height: 200px !important;
  }
  #tooltip-right > .tooltip-inner {
    max-width: 100% !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and  (min-width:1800px){
  .dashboard-card {
    height: 150px !important;
    width: 150px !important;
  }

  .dashboard-header {
    top: 50% !important;
    left: 50% !important;
  }

  .welcome_text {
    font-weight: 400 !important;
  }

  .mainpage__transform {
    width: 85% !important;
    .welcome_text {
      font-weight: 400 !important;
    }

    .dashboard-header {
      top: 50% !important;
      left: 50% !important;
    }
  }

  .mainpage__transform {
    left: 15% !important;
    
  }

  .flex-column.active-sidebar {
    width: 15% !important;
    
  }

  .drag-image-modal {
    display: block;
    width: -webkit-fill-available;
    height: 200px !important;
  }
  #tooltip-right > .tooltip-inner {
    max-width: 100% !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 2.5) {
  .dashboard-card {
    height: 100px !important;
    width: 100px !important;
  }

  .dashboard-header {
    top: 50% !important;
    left: 50% !important;
  }

  .welcome_text {
    font-weight: 400 !important;
  }

  .mainpage__transform {
    width: 75% !important;
    .welcome_text {
      font-weight: 400 !important;
    }

    .dashboard-header {
      top: 50% !important;
      left: 50% !important;
    }

    .dashboard-card {
      height: 120px !important;
      width: 120px !important;
    }
  }

  .mainpage__transform {
    left: 25% !important;
    
  }

  .flex-column.active-sidebar {
    width: 25% !important;
  }

  .drag-image-modal {
    display: block;
    width: -webkit-fill-available;
    height: 200px !important;
  }

  #tooltip-right > .tooltip-inner {
    max-width: 100% !important;
  }
}


@media (-webkit-min-device-pixel-ratio: 2) and (max-width:500px) {
  .dashboard-card {
    height: 100px !important;
    width: 100px !important;
  }

  .dashboard-header {
    top: 50% !important;
    left: 50% !important;
  }

  .welcome_text {
    font-size: 20px !important;
    font-weight: 400 !important;
  }

  .mainpage__transform {
    width: 75% !important;
    .welcome_text {
      font-weight: 400 !important;
    }

    .dashboard-header {
      top: 50% !important;
      left: 50% !important;
    }

    .dashboard-card {
      height: 120px !important;
      width: 120px !important;
    }
  }

  .mainpage__transform {
    left: 100% !important;
    
  }

  .flex-column.active-sidebar {
    width: 100% !important;
  }

  .drag-image-modal {
    display: block;
    width: -webkit-fill-available;
    height: 200px !important;
  }

  #tooltip-right > .tooltip-inner {
    max-width: 100% !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (max-width:768px) and (min-width:500px) {
  .dashboard-card {
    height: 100px !important;
    width: 100px !important;
  }

  .dashboard-header {
    top: 50% !important;
    left: 50% !important;
  }

  .welcome_text {
    font-weight: 400 !important;
  }

  .mainpage__transform {
    width: 70% !important;
    .welcome_text {
      font-weight: 400 !important;
    }

    .dashboard-header {
      top: 50% !important;
      left: 50% !important;
    }

    .dashboard-card {
      height: 120px !important;
      width: 120px !important;
    }
  }

  .mainpage__transform {
    left: 30% !important;
    
  }

  .flex-column.active-sidebar {
    width: 30% !important;
  }

  .drag-image-modal {
    display: block;
    width: -webkit-fill-available;
    height: 200px !important;
  }

  #tooltip-right > .tooltip-inner {
    max-width: 100% !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 2.5) and  (min-width:1800px){
  .dashboard-card {
    height: 100px !important;
    width: 100px !important;
  }

  .dashboard-header {
    top: 50% !important;
    left: 50% !important;
  }

  .welcome_text {
    font-weight: 400 !important;
  }

  .mainpage__transform {
    width: 85% !important;
    .welcome_text {
      font-weight: 400 !important;
    }

    .dashboard-header {
      top: 50% !important;
      left: 50% !important;
    }

    .dashboard-card {
      height: 120px !important;
      width: 120px !important;
    }
  }

  .mainpage__transform {
    left: 15% !important;
    
  }

  .flex-column.active-sidebar {
    width: 15% !important;
  }

  .drag-image-modal {
    display: block;
    width: -webkit-fill-available;
    height: 200px !important;
  }

  #tooltip-right > .tooltip-inner {
    max-width: 100% !important;
  }
}
