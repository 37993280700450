//Import Bootstrap
@import "~bootstrap/scss/bootstrap";
@import '~react-intl-tel-input/dist/main.css';


//Bootstrap(Pre load Variables and other things) 
@import "./bootstrap/bootstrap.scss";

//Layout
@import "./layout/layout.scss";

//Common 
@import "./common/common.scss";

//Pages
@import "./pages/pages.scss";

// global CSS for all
@import "./global.scss";

// text editor CSS
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

